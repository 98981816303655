import { Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { filter, map } from 'lodash';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikArray, P2PProviderSelect } from 'components';
import { TranslationNamespace } from 'i18n';

import { Values } from '../ManageShop';

type Props = {
  assetCurrencyIds?: string[];
  tradeMethodsIds: string[];
};

export const ShopP2PProviders: React.FC<Props> = ({
  assetCurrencyIds,
  tradeMethodsIds,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.shops.manage',
  });
  const formik = useFormikContext<Values>();

  return (
    <Fragment>
      <Typography variant="subtitle1">
        {t('sections.p2p_providers.title')}
      </Typography>
      <FormikArray
        addItemLabel={t('buttons.add_p2p_provider')}
        name="p2pProvidersConfigs"
        sortable
        removable
        defaultValue={{ p2pProviderId: '' }}
        ItemComponent={({ item, index }) => {
          const exclude = map(
            filter(
              formik.values.p2pProvidersConfigs,
              (config) => config.p2pProviderId !== item.p2pProviderId,
            ),
            (t) => t.p2pProviderId,
          );

          return (
            <P2PProviderSelect
              assetCurrencyIds={assetCurrencyIds}
              exclude={exclude}
              tradeMethodsIds={tradeMethodsIds}
              sx={{ mr: 1, flex: 1 }}
              name={`p2pProvidersConfigs[${index}].p2pProviderId`}
              label={t('fields.p2p_provider')}
            />
          );
        }}
      />
    </Fragment>
  );
};
